.orange {color: var(--secondary);}
.show-filters-mob {display: none;width: 100%;max-width: 30.8rem;height: 4.5rem;margin: 0 auto;border-radius: 1rem;}

.pagination {
  padding-bottom: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    width: 5rem;
    height: 5rem;
    border-radius: 2rem;
    background-color: var(--white);
    color: var(--primary);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, .1);
    transition: all .3s linear;
    &:hover {
      background-color: var(--primary);
      color: var(--white);
    }
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

.slick-dots {
  display: flex!important;
  justify-content: center;
  align-items: center;
  bottom: .8rem;
  li {
    width: 1rem;
    height: 1rem;
    margin: 0;
    &:not(:last-child) {
      margin-right: 1rem;
    }
    &.slick-active {
      button {
        background-color: var(--secondary);
      }
    }
    button {
      width: inherit;
      height: inherit;
      padding: 0;
      background-color: var(--light-orange);
      border-radius: 50%;
      transition: all .3s linear;
    }
  }
}

.card {
  background-color: var(--white);
  box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
  border-radius: 1rem;
  &__company {
    padding: 2.4rem 0;
    margin: 0 3rem;
    border-bottom: .1rem solid var(--silver3);
    & > * {
      max-width: 17.5rem;
      margin: 0 auto;
    }
  }
  &__info {
    padding: 2rem 3rem;
    & > * {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(last-child) {
        margin-bottom: 1rem;
      }
    }
  }
  & > .btn {
    border-radius: 0 0 1rem 1rem;
  }
}

.credit-card {
  display: flex;
  align-items: center;
  box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
  border-radius: 1rem;
  &__img {
    width: 100%;
    max-width: 33rem;
    border-radius: 1rem 0 0 1rem;
    & > * {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      display: block;
      border-radius: inherit;
    }
  }
  &__desc {
    width: 100%;
    max-width: 26.5rem;
    padding: 1.5rem 2rem;
    background-color: var(--white);
    border-radius: 0 1rem 1rem 0;
    .desc__raiting {
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-testimonials {
        font-size: 1.3rem;
        line-height: 1.7rem;
        color: var(--silver8);
      }
    }
    & > h3 {
      margin-bottom: 1rem;
      color: var(--dark-blue)
    }
    .desc__info {
      margin-bottom: 1.2rem;
      & > * {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.6rem;
        line-height: 2.1rem;
        color: var(--brown);
        &:not(:last-child) {
          margin-bottom: .5rem;
        }
      }
    }
    .desc__btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > * {
        min-width: 10.7rem;
        height: 3.5rem;
        padding: 1rem;
        font-size: 1.2rem;
        line-height: 1.5rem;
        &:not(:last-child) {
          margin-right: 1.1rem;
        }
      }
    }
  }
}


.main-best {
  padding-top: 5rem;
  padding-bottom: 7rem;
  &__ttl {
    margin-bottom: 2rem;
  }
  &__navigation {
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.debit-tabs {
      & > * {
        max-width: 25.2rem;
      }
    }
    & > * {
      width: 100%;
      max-width: 20.9rem;
      min-height: 3.5rem;
      padding: 1rem;
      background-color: var(--silver2);
      font-size: 1.2rem;
      line-height: 1.5rem;
      letter-spacing: 0.05rem;
      text-align: center;
      text-transform: uppercase;
      color: var(--lighter-blue);
      transition: all .3s linear;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        border-radius: 1rem 0 0 1rem;
      }
      &:last-child {
        border-radius: 0 1rem 1rem 0;
      }
      &:not(:last-child) {
        margin-right: .1rem;
      }
      &:hover {
        color: var(--primary);
      }
      &.active {
        background-color: var(--primary);
        color: var(--white);
      }
    }
  }

  &__tabs {
    margin: 0 -1.5rem 4rem;
    &.debit__tabs {
      margin: 0;
    }
  }
  &__tab {
    display: none;
    flex-wrap: wrap;
    &.active {
      display: flex;
    }
    &.slider-tab {
      justify-content: flex-start;
      .slick-arrow {
        z-index: 2;
        width: 1.2rem;
        height: 1rem;
        background: url("../img/icons/i-mobile-menu-arr.svg") center / contain no-repeat;
        &.slick-prev {
          left: 0;
          transform: translateY(-50%) rotate(180deg);
        }
        &.slick-next {
          right: 0;
        }
      }
    }
    &.other-cards {
      & > * {
        width: calc((100%/2) - 3rem);
      }
    }
    &.debit__tab {
      max-width: 100%;
      & > * {
        width: 100%;
        margin: 0;
        word-break: break-word;
      }
    }
    & > * {
      width: calc((100%/3) - 3rem);
      margin: 0 1.5rem;
    }
  }

  & > .btn {
    max-width: 28.3rem;
    margin: 0 auto;
  }
}

.seo {
  display: flex;
  align-items: center;
  &__wrap {
    padding: 7rem 0;
    background-color: var(--silver7);
    &-ttl {
      margin-bottom: 5rem;
    }
  }
  &__content {
    width: 100%;
    max-width: 59.5rem;
    margin-right: 3rem;
    & > * {
      font-weight: normal;
      font-size: 1.8rem;
      line-height: 2.3rem;
      color: var(--darker-blue);
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
  &__img {
    position: relative;
    width: 100%;
    max-width: 59.5rem;
    border-radius: 1rem;
    &-main {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      display: block;
      border-radius: inherit;
    }
    &-icon {
      width: 23.1rem;
      position: absolute;
      z-index: 2;
      bottom: -6rem;
      right: -6rem;
    }
    &-figure {
      width: 46.3rem;
      position: absolute;
      z-index: 0;
      top: -5.3rem;
      right: -19.2rem;
      transform: rotate(40deg);
    }
  }
}

.main-news {
  padding-top: 7rem;
  padding-bottom: 3.5rem;
  &__ttl {
    margin-bottom: 5rem;
  }
  & > .btn {
    max-width: 28.3rem;
    margin: 1rem auto 0;
  }
  &__links {
    width: 100%;
    margin-bottom: 3rem;
    border-bottom: .1rem solid var(--silver6);
    &-scrolled {
      width: 98rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      & > * {
        position: relative;
        padding-bottom: 2rem;
        font-size: 1.6rem;
        line-height: 2.1rem;
        color: var(--lighter-blue);
        transition: all .3s linear;
        &.active {
          color: var(--primary);
          border-bottom: .1rem solid var(--primary);
          pointer-events: none;
        }
        &:hover {
          color: var(--light-blue);
        }
        &:not(:last-child) {
          margin-right: 3rem;
        }
      }
    }
  }
  &__blocks {
    display: flex;
    margin: 0 -1.5rem;
    &.reverse {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
  &__block {
    width: 100%;
    max-width: calc((100% / 2) - 3rem);
    margin: 0 1.5rem 3rem;
    display: flex;
    background-color: var(--white);
    box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
    border-radius: 1rem;
    &.big-block {
      max-width: calc((100% / 2) - 3rem);
      flex-direction: column;
      .main-news__block-img {
        width: 100%;
        height:23.8rem;
        border-radius: 1rem 1rem 0 0;
      }
      .main-news__block-content {
        padding: 1.5rem 3rem 3.5rem;
      }
      .main-news__block-title {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        & > * {
          font-size: 1.8rem;
          line-height: 2.3rem;
        }
      }
    }
    &-img {
      position: relative;
      width: 20.8rem;
      border-radius: 1rem 0 0 1rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        display: block;
        border-radius: inherit;
      }
    }
    &-mark {
      position: absolute;
      top: 1rem;
      right: 1rem;
      padding: .8rem 1rem .6rem;
      background-color: rgba(255, 255, 255, .9);
      border-radius: .5rem;
      font-size: 1rem;
      line-height: 1.3rem;
      color: var(--primary);
      text-transform: uppercase;
      text-align: center;
      transition: all .3s linear;
      &:hover {
        background-color: rgba(11, 99, 218, .9);
        color: var(--white);
      }
    }
    &-content {
      padding: 2rem 2rem 3rem 3rem;
      flex: 1;
    }
    &-info {
      margin-bottom: 2.5rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      & > * {
        font-weight: normal;
        font-size:1.2rem;
        line-height: 1.5rem;
        color: var(--lighter-blue);
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
    &-title {
      margin-bottom: 1rem;
      & > * {
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: var(--dark-blue);
      }
    }
    &-text {
      margin-bottom: 2rem;
      & > * {
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: var(--dark-blue);
      }
    }
    &-link {
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      line-height: 1.7rem;
      transition: all .3s linear;
      color: var(--primary);
      text-transform: uppercase;
      text-decoration: underline;
      &:hover {
        color: var(--light-blue);
      }
    }
  }
  &__small-blocks {
    display: flex;
    flex-direction: column;
    max-width: calc((100% / 2) - 3rem);
    .main-news__block{
      max-width: 100%;
    }
  }
}

.main-calc__wrap {
  position: relative;
  margin: 3.5rem 0;
  padding: 7rem 0;
  &.debit-cards__calc {
    margin: 0;
    padding: 3.5rem 0 7rem;
    .main-calc__wrap-bg {
      display: none;
    }
  }
  &-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    & > * {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      display: block;
    }
  }
}


.ui-widget.ui-widget-content {
  width: 100%;
  height: 1rem;
  position: relative;
  margin: 0 auto;
  border: none;
  border-radius: 1rem;
  background: var(--silver6);
}

.ui-slider-handle {
  box-shadow: 0px 0.4rem 2.5rem rgba(75, 107, 194, 0.2);
  border-radius: 50%;
  background: #393939 !important;
  border: none !important;
  width: 1.6rem !important;
  height: 1.6rem !important;
  cursor: pointer !important;
  z-index: 3 !important;
  box-shadow: 0 0.4rem 0.8rem 0 rgba(34, 64, 71, 0.2);
  top: -.3em !important;
  width: 1.3rem !important;
  height: 1.3rem !important;
}

//.ui-widget.ui-widget-content::before {
//  background: #393939;
//  left: -0.5rem;
//}
//.ui-widget.ui-widget-content::after {
//  background: #E5E5E5;
//  right: -0.5rem;
//}

.ui-widget.ui-widget-content::before, .ui-widget.ui-widget-content::after {
  content: '';
  display: inline-block;
  width: 2%;
  height: 0.4rem;
  border-radius: 1rem;
  position: absolute;
  z-index: 1;
}
.calculator-sm .ui-widget.ui-widget-content::before {
  left: -0.6rem;
}
.calculator-sm .ui-widget.ui-widget-content::after {
  right: -0.6rem;
}
.calculator-sm .ui-widget.ui-widget-content::before, .calculator-sm .ui-widget.ui-widget-content::after {
  width: 1%;
}


.main-calc {
  &__content {
    position: relative;
    width: 100%;
    max-width: 86.4rem;
    margin: 0 auto;
    padding: 5rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--white);
    box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
    border-radius: .5rem;
    .icon {
      position: absolute;
      &.calc-message {
        width: 5.3rem;
        top: -2.2rem;
        right: 2.6rem;
      }
      &.calc-plant {
        width: 13.3rem;
        z-index: -1;
        bottom: 1rem;
        left: -9.7rem;
      }
    }
    & > .btn {
      min-width: 28.3rem;
    }
  }
  &__ttl,
  &__subttl {
    & > * {
      text-align: center;
      text-transform: uppercase;
    }
  }
  &__ttl {
    margin-bottom: 1rem;
    & > * {
      font-size: 2.4rem;
      line-height: 3.1rem;
    }
  }
  &__subttl {
    & > * {
      font-size: 1.3rem;
      line-height: 1.7rem;
    }
  }
  &__calc {
    width: 100%;
    padding: 5rem 0 3rem;
  }

  .slider-container {
    .slider-range {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      &.sum-slider {
        margin-bottom: 4.1rem;
      }
      .tooltip {
        display: flex;
        align-items: center;
        color: var(--secondary);
        text-transform: uppercase;
        & > * {
          font-size: 1.8rem;
          line-height: 2.3rem;
        }
      }
    }
  }

  .slider-range-int {
    background-color: transparent;
    width: 50%;
    color: var(--secondary);
    text-align: right;
    margin-right: .3rem;
    border: none;
    font-size: 1.8rem;
    font-weight: 500;
  }

  .slider-range-text {
    width: 100%;
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--dark-blue2);
  }

  .ui-slider-horizontal .ui-slider-range-min {
    border-radius: 1rem;
    background-color: var(--primary);
  }

  .ui-slider-handle {
    background: var(--white) !important;
    border: 1rem solid var(--primary)!important;
    width: 3rem !important;
    height: 3rem !important;
    top: -.7em !important;
  }

  .ui-widget.ui-widget-content {
    margin-top: 2.5rem;
    //&::before{
    //  background-color: var(--primary);
    //}
  }

  .slider-range-value {
    color: var(--secondary);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
    font-size: 1.8rem;
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loan-data {
    width: inherit;
    padding: 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--silver6);
    border-radius: .5rem;
    background-color: var(--silver7);
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.8rem;
      line-height: 2.3rem;
      color: var(--dark-blue3);
      &:not(:last-child) {
        margin-right: 5rem;
      }
      & > * {
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
      .tooltip{
        font-weight: 500;
        color: var(--dark-blue3);
      }
    }
  }
}

.main-comments {
  padding-top: 3.5rem;
  padding-bottom: 7rem;
  &__ttl {
    margin-bottom: 5rem;
  }
  & > .btn {
    max-width: 28.3rem;
    margin: 0 auto;
  }
}

input.hidden {
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  opacity: 0;
}

.form_row input,
.form_row textarea {
  width: 100%;
  height: 4.2rem;
  border: none;
  border-bottom: 0.1rem solid #E7E9EF;
  font-size: 2.4rem;
  line-height: 3.1rem;
  padding: 0;
}

.form_row input.disabled,
.form_row textarea.disabled{
  color: #6c6c6c;
}

.form_row input:-ms-input-placeholder,
.form_row textarea:-ms-input-placeholder {
  color: #CECECE;
  font-size: 2.4rem;
  line-height: 3.1rem;
}

.form_row input::placeholder,
.form_row textarea::placeholder {
  color: #CECECE;
  font-size: 2.4rem;
  line-height: 3.1rem;
}

.form_row input.error,
.form_row textarea.error {
  border-bottom: 0.1rem solid red;
}


.dis_req {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 2.3rem;
  cursor: pointer;
}

.dis_req .checked {
  width: 2rem;
  height: 2rem;
  border: 0.1rem solid #84A4B4;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
}

.dis_req svg {
  position: relative;
  opacity: 0;
  transition: 0.2s linear;
}

.dis_req span {
  /*color: 1.4rem;*/
  line-height: 1.8rem;
  color: #84A4B4;
  margin-left: 1rem;
}

.dis_req.active svg {
  opacity: 1;
}


.post-com__warning {
  padding: 2rem 3rem;
  margin-bottom: 1rem;
  background-color: var(--silver7);
  box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.4);
  border-radius: .5rem;
}

.post-com__warning > * {
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  font-size: 1.6rem;
  line-height: 2.1rem;
  text-align: center;
}

.post-com__warning > * a {
  color: var(--primary);
  text-decoration: underline;
  transition: all .3s linear;
}
.post-com__warning > * a:hover {
  color: var(--light-blue);
}

.post-com__form {
  background: var(--white);
  box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.4);
  border-radius: .5rem;
  padding: 5rem 10.4rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.post-com__form .form_row {
  width: 30rem;
  margin-top: 0;
}

.post-com__form .section__title {
  width: 100%;
  margin-bottom: 5rem;
}
.post-com__form .section__title > * {
  text-align: center;
}

.post-com__stars {
  width: 20rem;
  margin-left: 5.6rem;
  display: flex;
  align-items: center;
  position: relative;
}

.post-com__form .form_row {
  position: relative;
}

.post-com__stars .form_row {
  position: absolute;
}

.post-com__form .errorText {
  color: red;
  position: absolute;
  left: 0;
  bottom: -1.9rem;
  font-size: 1.3rem;
}

.post-com__stars .errorText {
  top: 2rem
}

.form_textarea .errorText {
  bottom: -1.8rem;
}

.post-com__form .form_row.hidden {
  width: 0;
}

.post-com__stars-imgs {
  display: flex;
  align-items: center;
}

.post-com__stars-imgs svg {
  margin-right: 1rem;
  cursor: pointer;
}

.post-com__stars-imgs svg path {
  transition: 0.1s linear;
}

.post-com__stars-imgs svg.active path:first-of-type {
  fill: #FFDC64;
}

.post-com__stars-imgs svg.active path:last-of-type {
  fill: #FFC850;
}

.post-com__stars-text > * {
  color: #AABBC6;
  font-size: 1.6rem;
  line-height: 2.1rem;
  font-weight: 500;
}

.post-com__form .form_textarea {
  width: 100%;
  margin-top: 3rem;
}

.post-com__form .form_textarea textarea {
  resize: vertical;
}

.post-com__form .form_textarea textarea::-webkit-scrollbar {
  display: none;
}

.post-com__form .btn {
  margin: 4rem auto 0;
  border: none;
  min-width: 28.3rem;
}
.post-com__form .dis_req {
  width: 100%;
  margin-top: 2rem;
}

.last-com__blocks {
  position: relative;
  margin: 0 -1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.post-com .last-com__blocks {
  margin: 0 0 3rem;
}

.post-com .last-com__block {
  max-width: 100%;
  margin: 0 0 1rem 0;
  padding: 5rem 10.4rem;
}

.post-com .last-com__top {
  margin-bottom: 3rem;
}

.post-com .last-com__name{
  flex:1
}

.post-com .last-com__stars-imgs svg {
  width: 2.1rem;
  height: 1.9rem;
}

.post-com .last-com__date{
  margin-right: 1rem;
}

.last-com__img {
  position: absolute;
  z-index: 1;
  top: 5%;
  right:-6rem;
  width: 13.3rem;
}

.last-com__block {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: calc((100% / 2) - 3rem);
  margin: 0 1.5rem 3rem 1.5rem;
  background-color:#fff;
  border-radius:0.5rem;
  box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.4);
  padding: 4rem 3rem 5rem 3rem;
}

.last-com__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.last-com__stars {
  display: flex;
  align-items: center;
}

.last-com__stars-imgs {
  display: flex;
  align-items: center;
}

.last-com__stars-imgs svg {
  margin-right: 0.5rem;
}

.last-com__stars-imgs svg.active path:first-of-type {
  fill: #FFDC64;
}

.last-com__stars-imgs svg.active path:last-of-type {
  fill: #FFC850;
}

.last-com__stars-text {
  margin-left: .5rem;
}

.last-com__stars-text > * {
  color:#AABBC6;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 500;
}

.last-com__date > * {
  color: #98A9B3;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.last-com__content {
  margin-top: 1rem;
}

.last-com__name > * {
  color: #003466;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.1rem;
}

.last-com__link {
  margin: 0.5rem 0 3rem 0;
}

.last-com__link > * {
  color: #98A9B3;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.last-com__link > a {
  text-decoration: underline;
  transition: 0.2s linear;
}

.last-com__link > a:hover {
  color: var(--primary);
  text-decoration: none;
}

.last-com__text > * {
  color:#223762;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.8rem;
}


.debit-first {
  max-width: 79rem;
  padding-top: 7.6rem;
  padding-bottom: 3.5rem;
  &__info {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    & > * {
      flex: 1 0 33.3333%;
      &:not(:last-child) {
        padding-right: 2rem;
      }
    }
  }
  &__name {
    & > * {
      font-size: 2.4rem;
      line-height: 3.1rem;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      color: var(--black);
    }
  }
  &__raiting {
    max-width: 20rem;
    .last-com__stars {
      margin-bottom: 1rem;
    }
    .last-com__stars-imgs svg {
      width: 2.2rem;
      height: 2rem;
    }
    .last-com__stars-text > * {
      font-size: 1.6rem;
      line-height: 2.1rem;
    }
  }
  &__testimonials {
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: var(--silver10);
  }
  &__company {
    max-width: 25rem;
    min-height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: .1rem solid var(--silver6);
    background-color: var(--white);
    & > * {
      max-width: 20.9rem;
    }
  }
  &__card {
    padding: 3rem 3rem 4rem;
    background: var(--white);
    box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__card-img {
    width: 100%;
    max-width: 34.9rem;
    height: 23.3rem;
    margin-right: 2rem;
    border-radius: 1rem;
    & > * {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      display: block;
      border-radius: inherit;
    }
  }
  &__card-options {
    width: 100%;
    max-width: 23.9rem;
    & > .btn {
      min-width: unset;
      max-width: 17.8rem;
      margin: 0 auto;
    }
  }
  &__card-list {
    margin-bottom: 3rem;
    & > * {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: .1rem solid var(--silver6);
      }
      & > * {
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: var(--silver9);
        &:first-child {
          text-transform: uppercase;
          color: var(--black);
        }
        &:last-child {
          min-width: 6.5rem;
          text-align: center;
        }
      }
    }
  }
}
.debit__tabs-wrap {
  position: relative;
  max-width: 79rem;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  .icon {
    width: 5.1rem;
    position: absolute;
    top: 50%;
    right: -30.1rem;
  }
}
.debit__tab {
  .tab__contetn {
    & > * {
      text-align: center;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
}

.team-mark__wrapper {
  margin: 3.5rem 0;
  padding: 7rem 0;
  background-color: var(--silver7);
}
.team-mark {
  &__ttl {
    margin-bottom: 5rem;
  }
  &__info {
    display: flex;
    & > * {
      &:not(:last-child) {
        margin-right: 3rem;
      }
    }
  }
  &__content {
    width: 100%;
    max-width: 59.5rem;
    & > * {
      font-weight: normal;
      font-size: 1.8rem;
      line-height: 2.3rem;
      color: var(--dark-blue3);
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
  &__lists {
    width: 100%;
    max-width: 28.2rem;
    .list__ttl {
      width: 100%;
      padding: .7rem;
      margin-bottom: 2rem;
      border-radius: 1rem;
      background-color: var(--white);
      & > * {
        font-size: 1.6rem;
        line-height: 2.1rem;
        letter-spacing: 0.05rem;
        text-align: center;
        text-transform: uppercase;
        color: var(--primary);
      }
    }
    & > ul {
      &.plus {
        & > * {
          &::before {
            content: '+';
          }
        }
      }
      &.minus {
        & > * {
          &::before {
            content: '-';
          }
        }
      }
      & > * {
        position: relative;
        padding-left: 3.3rem;
        font-weight: normal;
        font-size: 1.8rem;
        line-height: 2.3rem;
        color: var(--dark-blue3);
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 50%;
          box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
          background-color: var(--white);
          font-size: 2.8rem;
          line-height: 3.2rem;
          color: var(--secondary);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.micro-selection {
  padding-top: 5.7rem;
  padding-bottom: 3.5rem;
  &__ttl {
    margin-bottom: 5rem;
  }
  &__blocks {
    display: flex;
  }
  &__other {
    width: 100%;
    max-width: 90.8rem;
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
  }
  &__calc {
    margin-bottom: 7rem;
    .main-calc__content {
      max-width: 100%;
      padding: 3rem 3rem 2rem;
    }
    .main-calc__calc {
      padding: 0;
    }
    .slider-range-value {
      text-transform: uppercase;
    }
    &.main-calc {
      .slider-container .slider-range.sum-slider {
        margin-bottom: 0;
      }
    }
  }
  &__best {
    &-ttl {
      margin-bottom: 5rem;
      & > * {
        text-align: left;
      }
    }
    &-blocks {
      width: 100%;
      .slick-arrow {
        z-index: 2;
        width: 1.2rem;
        height: 1rem;
        background: url("../img/icons/i-mobile-menu-arr.svg") center / contain no-repeat;
        &.slick-prev {
          left: 0;
          transform: translateY(-50%) rotate(180deg);
        }
        &.slick-next {
          right: 0;
        }
      }
      .slick-dots {
        bottom: -.5rem;
      }
      & > * {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
    &-block {
      min-height: 15.5rem;
      padding: 2rem 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--white);
      box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
      border-radius: .5rem;
      & > * {
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
      .block__company {
        display: flex;
        justify-content: center;
        align-items: center;
        & > * {
          max-width: 17.5rem;
        }
      }
      .block__name {
        & > * {
          &:not(:last-child) {
            margin-bottom: 1.1rem;
          }
        }
        .last-com__stars-imgs {
          svg {
            width: 2rem;
            height: 1.9rem;
          }
        }
      }
      .block__info {
        & > * {
          color: var(--brown);
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
      .block__btns {
        & > * {
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
}

.micro-other {
  padding-top: 3.5rem;
  padding-bottom: 4rem;
  &__ttl {
    margin-bottom: 5rem;
  }
  &__blocks {
    margin: 0 -1.5rem;
    display: flex;
    flex-wrap: wrap;
  }
  &__block {
    width: calc((100%/4) - 3rem);
    margin: 0 1.5rem 3rem;
    background: var(--white);
    box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
    border-radius: .5rem;
    .block__company {
      width: 100%;
      padding: 3.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img {
        max-width: 17.5rem;
      }
    }
    .block__name {
      margin-bottom: 1.5rem;
      padding: .5rem .5rem .2rem;
      background-color: var(--silver6);
      & > * {
        font-size: 1.1rem;
        line-height: 1.4rem;
        letter-spacing: 0.04rem;
        text-align: center;
        text-transform: uppercase;
        color: var(--silver9);
      }
    }
    .last-com__stars {
      justify-content: center;
      .last-com__stars-imgs {
        svg {
          width: 2.2rem;
          height: 2rem;
        }
      }
      .last-com__stars-text > * {
        font-size: 1.6rem;
        line-height: 2.1rem;
      }
    }
    .block__info {
      padding: 1.5rem 2rem 2rem;
      & > * {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        & > * {
          font-size: 1.6rem;
          line-height: 2.1rem;
        }
      }
    }
    .block__btns {
      padding: 0 2rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > * {
        flex: 1 0 11.7rem;
        min-width: auto;
        border-radius: 1rem;
        &:not(:last-child) {
          margin-right: .9rem;
        }
      }
    }
  }
  &__load-more {
    display: none;
    max-width: 16.5rem;
    margin: 0 auto;
  }
}

.debit-best {
  padding-top: 3.5rem;
  padding-bottom: .5rem;
  &__slider-wrap {
    position: relative;
    width: 100%;
    max-width: 130rem;
    margin: 0 auto;
  }
  &__slider-btns {
    .slick-arrow {
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 50%;
      background-color: var(--white);
      box-shadow: .1rem .1rem 1rem rgba(20, 111, 251, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all .3s linear;
      &:hover {
        background-color: var(--light-blue);
        svg {
          path {
            fill: var(--white);
          }
        }
      }
      &.cards-prev {
        left: -4rem;
        svg {
          transform: rotate(180deg);
        }
      }
      &.cards-next {
        right: -4rem;
      }
      svg {
        path {
          transition: all .3s linear;
        }
      }
    }
  }
  &__slider {
    width: 100%;
    &.slick-dotted {
      margin-bottom: 0;
    }
    .slick-list {
      padding: 3rem 0;
    }
    .credit-card {
      max-width: 59.5rem;
      margin: 0 auto;
    }
    .card {
      max-width: 34.9rem;
      margin: 0 auto;
    }
  }
}

.debit-testimonials-form {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.debit-testimonials {
  position: relative;
  padding-top: 3.5rem;
  padding-bottom: 7rem;
  &__ttl {
    margin-bottom: 5rem;
  }
  .plant {
    width: 13.3rem;
    position: absolute;
    top: 16rem;
    right: -5.8rem;
    transform: scale(-1, 1);
  }
  & > .btn {
    max-width: 28.3rem;
    margin: 0 auto;
  }
}

.debits__main {
  padding-top: 5.7rem;
  padding-bottom: 3.5rem;
  &-ttl {
    margin-bottom: 5rem;
  }
  &-options {
    display: flex;
  }
  &-best {
    width: 100%;
    max-width: 90.8rem;
    margin-right: 3rem;
    padding: 3rem 2rem 3rem 3rem;
    background-color: var(--white);
    box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
    border-radius: .5rem;
    .best__ttl {
      margin-bottom: 3rem;
      & > * {
        font-size: 1.8rem;
        line-height: 2.3rem;
        letter-spacing: 0.05rem;
        text-transform: uppercase;
        color: var(--brown);
      }
    }
    .best__cards {
      .slick-arrow {
        z-index: 2;
        width: 1.2rem;
        height: 1rem;
        background: url("../img/icons/i-mobile-menu-arr.svg") center / contain no-repeat;
        &.slick-prev {
          left: 0;
          transform: translateY(-50%) rotate(180deg);
        }
        &.slick-next {
          right: 0;
        }
      }
      & > * {
        &:not(:last-child) {
          margin-bottom: 2rem;
          padding-bottom: 2rem;
          border-bottom: .1rem solid var(--silver3);
        }
        .credit-card {
          box-shadow: unset;
          border-radius: unset;
          &__desc {
            max-width: 100%;
            .desc__info {
              display: flex;
              flex-wrap: wrap;
              margin: 0 -2rem 1rem;
              & > * {
                width: calc((100%/2) - 4rem);
                margin: 0 2rem 1rem;
              }
            }
            .desc__btns {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
  .filters {
    min-height: auto;
    height: 64.8rem;
    &__inps {
      height: 47vh;
    }
  }
}

.debits__other {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  &-ttl {
    margin-bottom: 5rem;
  }
  &-blocks {
    margin: 0 -3rem 1rem;
    display: flex;
    flex-wrap: wrap;
    & > * {
      width: calc((100%/2) - 3rem);
      margin: 0 1.5rem 3rem;
    }
  }
  & > .btn {
    min-width: auto;
    max-width: 28.3rem;
    margin: 0 auto;
  }
}

.debits__faq {
  max-width: 89.6rem;
  &-wrap {
    position: relative;
    margin: 3.5rem 0;
    padding: 7rem 0;
  }
  &-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    & > * {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      display: block;
    }
  }
  &-ttl {
    margin-bottom: 3rem;
  }
}


.faq {
  &__block {
    width: 100%;
    border-radius: 1rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &.open {
      .faq__block-ttl {
        &::after {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
    &-ttl {
      position: relative;
      padding: 2.5rem 3.75rem;
      background-color: var(--white);
      box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
      border-radius: inherit;
      cursor: pointer;
      &::after {
        content: '';
        width: 1.2rem;
        height: 1rem;
        position: absolute;
        right: 3.75rem;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        background: url("../img/icons/i-mobile-menu-arr.svg") center / contain no-repeat;
        transition: all .3s linear;
      }
      & > * {
        font-size: 1.8rem;
        line-height: 2.3rem;
        color: var(--dark-blue);
        text-align: center;
      }
    }
    &-content {
      display: none;
      padding: 3rem;
      background-color: var(--white);
      & > * {
        font-size: 1.8rem;
        line-height: 2.3rem;
        color: var(--darker-blue);
      }
    }
  }
}

.consumer-single__main {
  max-width: 79rem;
  padding-top: 7.6rem;
  padding-bottom: 3.5rem;
  &-top {
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & > * {
      flex: 1 0 33.3333%;
    }
    .top__ttl {
      & > * {
        font-size: 2.4rem;
        line-height: 3.1rem;
      }
    }
    .top__raiting {
      width: 100%;
      max-width: 19rem;
      .last-com__stars-imgs svg {
        width: 2.2rem;
        height: 2rem;
      }
      &-testimonials {
        display: block;
        margin-top: .9rem;
        font-size: 1.6rem;
        line-height: 2.1rem;
        color: var(--silver10);
      }
    }
    .top__company {
      max-width: 25rem;
      min-height: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--white);
      border: .1rem solid var(--silver6);
      & > * {
        max-width: 20.9rem;
      }
    }
  }
  &-middle {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .plant {
      width: 6.5rem;
      position: absolute;
      z-index: -1;
      top: -1rem;
      left: -4rem;
    }
    .middle__info {
      width: 100%;
      max-width: 24.1rem;
      flex: 1 0 18rem;
      padding: 1rem;
      margin-bottom: 2rem;
      background-color: var(--white);
      box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
      border-radius: .5rem;
      display: flex;
      align-items: center;
      &:not(:nth-of-type(3n)) {
        margin-right: 1.7rem;
      }
      &-img {
        width: 100%;
        max-width: 7rem;
        height: 7rem;
        margin-right: 1rem;
        background-color: var(--white);
        border: .1rem solid var(--silver6);
        border-radius: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        & > * {
          width: 4rem;
          height: 4rem;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        & > * {
          &:not(:last-child) {
            margin-bottom: .5rem;
          }
        }
        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6 {
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: var(--primary);
        }
        & > p {
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: var(--silver11);
        }
      }
    }
  }
  .main-calc {
    &__content {
      padding: 3rem;
    }
    &__calc {
      padding: 0 0 3rem;
    }
  }
}
.consumer-single__get {
  position: relative;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  max-width: 79rem;
  .plant {
    width: 10rem;
    position: absolute;
    z-index: -1;
    bottom: 2.5rem;
    left: -4.3rem;
  }
  &-ttl {
    margin-bottom: 3.9rem;
  }
  &-blocks {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.8rem;
  }
  &-block {
    width: calc((100%/3) - 1.8rem);
    margin: 0 .9rem;
    padding: 2rem;
    background-color: var(--white);
    box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .block__img {
      width: 100%;
      max-width: 7rem;
      min-height: 7rem;
      margin-bottom: 2rem;
      background-color: var(--white);
      border: .1rem solid var(--silver6);
      border-radius: .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      & > * {
        width: 4rem;
        height: 4rem;
      }
    }
    .block__content {
      width: 100%;
      max-width: 14.1rem;
      & > * {
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-align: center;
        color: var(--dark-blue3);
      }
    }
  }
}

.consumer-single__best {
  padding: 7rem 0 3rem;
  margin: 3.5rem 0;
  background-color: var(--silver7);
  .debit-best {
    padding: 0;
  }
}

.post {
  padding-top: 5.9rem;
  padding-bottom: 3.5rem;
  max-width: 83.5rem;
  &__options,
  &__date,
  &__share,
  &__tags {
    display: flex;
    align-items: center;
  }
  &__options {
    margin-bottom: 1rem;
    justify-content: space-between;
  }
  &__date {
    & > * {
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: var(--silver8);
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  &__share {
    & > * {
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: var(--silver8);
    }
    & > a {
      width: 3rem;
      height: 3rem;
      margin-left: 1rem;
      border-radius: .5rem;
    }
  }
  &__img {
    height: 33.7rem;
    margin-bottom: 4rem;
    border-radius: 1rem;
  }
  &__tags {
    & > * {
      min-height: 3rem;
      padding: 0.35rem 1rem;
      background-color: var(--silver6);
      border-radius: 0.5rem;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__content {
    margin-top: 2rem;
    & > * {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
    & > h1 {font-size: 2.4rem;line-height: 3.1rem;}
    & > h2 {font-size: 2.1rem;line-height: 2.8rem;}
    & > h3 {font-size: 1.8rem;line-height: 2.3rem;}
    & > h4 {font-size: 1.6rem;line-height: 2.1rem;}
    & > h5 {font-size: 1.3rem;line-height: 1.7rem;}
    & > h6 {font-size: 1.2rem;line-height: 1.5rem;}
    & > h1, & > h2, & > h3, & > h4, & > h5, & > h6 {
      text-transform: unset;
      color: var(--black);
    }
    & > ul > li, & > ol > li, & > p {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
    & > ul, & > ol {
      & > li {
        position: relative;
        padding-left: 1.5rem;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        & > ul {
          margin-top: 1rem;
          & > li {
            position: relative;
            padding-left: 2rem;
            &:not(:last-child) {
              margin-bottom: 1rem;
            }
            &::before {
              content: '';
              width: .8rem;
              height: .8rem;
              position: absolute;
              top: .6rem;
              left: 0;
              border-radius: 50%;
              background-color: var(--primary);
            }
          }
        }
        & > ol {
          margin-top: 1rem;
          counter-reset: list-counter;
          & > li {
            position: relative;
            padding-left: 2rem;
            counter-increment: list-counter;
            &:not(:last-child) {
              margin-bottom: 1rem;
            }
            &::before {
              content: counter(list-counter)'.';
              position: absolute;
              top: 0;
              left: 0;
              font-weight: bold;
            }
          }
        }
      }
    }
    & > ul {
      & > li {
        &::before {
          content: '';
          width: .8rem;
          height: .8rem;
          position: absolute;
          top: .6rem;
          left: 0;
          border-radius: 50%;
          background-color: var(--primary);
        }
      }
    }
    & > ol {
      counter-reset: list-counter;
      & > li {
        counter-increment: list-counter;
        &::before {
          content: counter(list-counter)'.';
          position: absolute;
          top: 0;
          left: 0;
          font-weight: bold;
        }
      }
    }
    & > blockquote {
      margin: 0;
      padding: 3rem;
      background-color: var(--white);
      box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.1);
      border-radius: 1rem;
    }
  }
}

.post-other {
  padding-top: 3.5rem;
  padding-bottom: 4rem;
  &__slider {
    width: 100%;
    .slick-list {
      padding: 3rem 0;
    }
    .slick-arrow {
      z-index: 2;
      width: 1.2rem;
      height: 1rem;
      background: url("../img/icons/i-mobile-menu-arr.svg") center / contain no-repeat;
      &.slick-prev {
        left: 0;
        transform: translateY(-50%) rotate(180deg);
      }
      &.slick-next {
        right: 0;
      }
    }
    .main-news__block {
      max-width: 50rem;
      margin: 0 auto;
    }
  }
}

footer {
  padding: 2.5rem 0 3rem;
  background-color: var(--white);
  box-shadow: 1rem 1rem 3rem rgba(11, 99, 218, 0.4);
}
.footer {
  &__top {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-logo {
      & > * {
        max-width: 19rem;
      }
    }
    & > ul {
      display: flex;
      align-items: center;
      & > li {
        & > * {
          color: var(--brown);
          text-transform: uppercase;
          transition: all .3s linear;
          &:hover {
            color: var(--light-blue);
          }
        }
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }
  }
  &__content {
    & > * {
      font-weight: normal;
      color: var(--dark-blue);
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
}
